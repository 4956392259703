import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Container } from 'reactstrap'

export default props => (
  <Layout>
    <Helmet>
      <title>Success Page</title>
      <meta name="description" content="Success Page" />
    </Helmet>
    <section className="py-5">
      <Container>
        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Thanks for getting in touch!</h1>
              </header>
              <p>A member of our team will get back to you shortly.</p>
            </div>
          </section>
        </div>
      </Container>
    </section>
  </Layout>
)
